import React, { Component } from "react";

const ServiceList = [
  {
    image: "blocks",
    altImage: "uni-blocks-image",
    contentImage: "uni-blocks",
    title: "UNI Blocks",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    image: "quadro",
    altImage: "sultania",
    contentImage: "quadro-content",
    title: "Quadro",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    image: "sultania",
    altImage: "sultania",
    contentImage: "sultania-pasco",
    title: "Sultaniya",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    image: "behaton",
    altImage: "sultania",
    contentImage: "behaton-pasco",
    title: "Behaton",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    image: "lotus",
    altImage: "sultania",
    contentImage: "lotus-pasco",
    title: "Lotus",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    image: "Ventagilio",
    altImage: "sultania",
    contentImage: "Ventagilio-pasco",
    title: "Ventagilio",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a>
                <div className="service service__style--2">
                  <img
                    style={{
                      position: "relative",
                      top: 0,
                      left: 0,
                    }}
                    src={`/assets/images/design/${val.image}.jpg`}
                    alt={`${val.title}`}
                  />

                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    {/* <p>{val.description}</p> */}
                    <img
                      style={{
                        position: "relative",
                        top: 0,
                        left: 0,
                        borderRadius: "5px",
                      }}
                      src={`/assets/images/design/${val.contentImage}.jpg`}
                      alt="Portfolio Images"
                    />
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
