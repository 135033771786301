import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Pasco Stones - الباسكو والحجر في أبوظبي </title>
          <meta
            name="description"
            content="Pasco stones - توريد وتركيب كل مستلزمات الباسكو والحجر ومستلزمات البناء بجودة ممتازة وأسعار منافسة في أبوظبي والعين وكافة الامارات"
          />
          <meta
            name="keywords"
            property="keywords"
            content="pasco,stone,marble,cement,abudhabi,alain,توريد ياسكو,توريد حجر,حجر,باسكو,رمل,اسمنت,العين,أبوظبي,باسكو في أبوظبي,حجر في أبوظبي,فلل"
          />
          <meta property="og:image" content="/assets/images/about/pasco2.jpg" />
          <meta
            property="og:image"
            content="https://pasco-stones.com/assets/images/about/pasco2.jpg"
          />
          <meta
            property="og:image:secure_url"
            content="https://pasco-stones.com/assets/images/about/pasco2.jpg"
          />
          <meta property="og:image:type" content="image/jpg" />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="300" />
          <meta
            property="og:image:alt"
            content="Pasco Stones - الباسكو والحجر في أبوظبي "
          />
          <meta
            property="og:title"
            content="Pasco stones - الباسكو والحجر في أبوظبي"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://pasco-stones.com" />
          <meta
            property="og:image:url"
            content="https://pasco-stones.com/assets/images/about/pasco2.jpg"
          />
          <meta
            property="og:description"
            content="Pasco stones - توريد وتركيب كل مستلزمات الباسكو والحجر ومستلزمات البناء بجودة ممتازة وأسعار منافسة في أبوظبي والعين وكافة الامارات"
          />

          <meta property="og:locale" content="ar-ae" />
          <meta property="og:site_name" content="Pasco Stones" />
          <meta
            property="og:updated_time"
            content="2022-20-30T12:13:26+00:00"
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Pasco Stones - الباسكو والحجر في أبوظبي"
          />
          <meta
            name="twitter:description"
            content="Pasco stones - توريد وتركيب كل مستلزمات الباسكو والحجر ومستلزمات البناء بجودة ممتازة وأسعار منافسة في أبوظبي والعين وكافة الامارات"
          />
          <meta name="twitter:site" content="Waad Dariz Architects" />
          <meta
            name="twitter:image"
            content="https://pasco-stones.com/assets/images/about/pasco2.jpg"
          />
          <meta
            name="robots"
            content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
          />
          <meta
            name="googlebot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <meta
            name="bingbot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />

          <meta content="telephone=no" name="format-detection" />
          <meta name="HandheldFriendly" content="true" />
          <meta http-equiv="x-ua-compatible" content="ie=edge" />
          <meta Http-Equiv="Cache-Control" Content="no-cache" />
          <meta Http-Equiv="Pragma" Content="no-cache" />
          <meta Http-Equiv="Expires" Content="0" />
          <meta Http-Equiv="Pragma-directive: no-cache" />
          <meta Http-Equiv="Cache-directive: no-cache" />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/assets/images/icons/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/assets/images/icons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/assets/images/icons/favicon-16x16.png"
          />
          <link rel="canonical" href="https://pasco-stones.com/" />
          <link rel="manifest" href="/assets/images/icons/site.webmanifest" />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
