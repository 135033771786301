import React, { Component } from "react";

let TeamContent = [
  {
    images: "3",
    title: "pasco-stone-abudhabi-cement",
    designation: "Sr. Web Developer",
  },
  {
    images: "13",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
    designation: "Front-end Engineer",
  },
  {
    images: "27",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
    designation: "Sr. Graphic Designer",
  },
  {
    images: "51",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
    designation: "Sr. Web Developer",
  },
  {
    images: "55",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
    designation: "Front-end Engineer",
  },
  {
    images: "51",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
    designation: "Sr. Graphic Designer",
  },
  {
    images: "44",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
    designation: "Sr. Graphic Designer",
  },
  {
    images: "6",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
    designation: "Sr. Graphic Designer",
  },
  {
    images: "45",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
    designation: "Sr. Graphic Designer",
  },
];

class Team extends Component {
  render() {
    const { column } = this.props;
    return (
      <React.Fragment>
        {TeamContent.map((value, i) => (
          <div className={`${column}`} key={i}>
            <div className="team">
              <div className="thumbnail">
                <div
                  className="parent"
                  style={{ position: "relative", top: 0, left: 0 }}
                >
                  <img
                    src={`/assets/images/pasco-stone-wm/pasco${value.images}.jpg`}
                    alt={`${value.title}`}
                  />
                  <img
                    style={{
                      position: "absolute",
                      top: "120px",
                      left: "40px",
                      width: "50%",
                    }}
                    src="/assets/images/logo/tran-logo.png"
                    alt={`${value.title}`}
                  />
                </div>
              </div>
              <div className="content">
                {/* <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p> */}
              </div>
              {/* <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul> */}
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default Team;
