export const pascoList = [
  {
    images: "9",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "15",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "16",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "17",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "18",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "20",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "24",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "25",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "26",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "28",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "29",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "38",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "40",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "46",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "47",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "48",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "49",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "50",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "52",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "56",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "58",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "62",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "64",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
  {
    images: "65",
    category: "Development",
    title: "pasco-stone-abudhabi-cement-باسكو-حجر-ابوظبي-الامارات",
  },
];
