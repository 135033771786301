import React, { useEffect } from "react";
import ScrollToTop from "react-scroll-up";
import FooterTwo from "../component/footer/FooterTwo";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFour";
import Helmet from "../component/common/Helmet";
import Team from "../elements/Team";

import { pascoList } from "../data/imagesList1";

import FloatingWhatsApp from "react-floating-whatsapp";

import ServiceList from "../elements/service/ServiceList";
import ReactGA from "react-ga";
import packageJson from "../../package.json";

const CreativePortfolio = () => {
  // ReactGA.pageview(window.location.pathname);
  // console.log("created");
  ReactGA.initialize("G-6J0CW8GKQD");
  useEffect(() => {
    caching();
    localStorage.clear();
    localStorage.setItem("version", packageJson.version);
    console.log("version stored");
  }, []);
  ReactGA.pageview(window.location.pathname);
  const caching = () => {
    let version = localStorage.getItem("version");
    if (version != packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
        console.log("cache cleared");
      }

      localStorage.clear();
      localStorage.setItem("version", packageJson.version);
    }
  };
  // const onButtonClick = () => {
  //   ReactGA.event({
  //     category: "User Actions",
  //     action: "Clicked  Button",
  //     label: "WhatsApp",
  //   });
  // };
  const onAskPriceButtonClick = () => {
    ReactGA.event({
      category: "User Actions",
      action: "Clicked  Button",
      label: "Ask Price",
    });
  };
  return (
    <div className="creative-portfolio">
      <Helmet pageTitle="Pasco Stones - الباسكو والحجر في أبوظبي " />

      {/* Start Header Area  */}
      <Header
        headerPosition="header--static"
        logo="symbol-dark"
        color="color-black"
      />
      {/* End Header Area  */}
      {/* Start Team Area  */}
      <div className="rn-team-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                <h2 className="title">Pasco Stones - باسكو و حجر </h2>
                <h3>
                  توريد وتركيب كل مستلزمات الباسكو والحجر ومستلزمات البناء بجودة
                  ممتازة وأسعار منافسة{" "}
                </h3>
                <h4>في أبوظبي والعين وكافة الامارات</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <Team
              column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
              teamStyle="team-style--bottom"
              item="3"
            />
          </div>
        </div>
      </div>
      {/* End Team Area  */}

      {/* Start Service Area  */}
      <div
        className="service-area creative-service-wrapper ptb--120 bg_color--1"
        id="service"
      >
        <div className="container">
          <div className="row creative-service">
            <div className="col-lg-12">
              <ServiceList
                item="6"
                column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
              />
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}
      {/* Start Portfolio Area  */}
      <div className="creative-portfolio-wrapper bg_color--1">
        <div className="creative-portfolio-wrapper plr--10">
          <div className="row row--5" style={{ marginBottom: "50px" }}>
            {pascoList.map((value, i) => (
              <div className="col-lg-3 col-md-6 col-12" key={i}>
                <div className="team">
                  <div className="thumbnail">
                    <div
                      className="parent"
                      style={{ position: "relative", top: 0, left: 0 }}
                    >
                      <img
                        style={{
                          position: "relative",
                          top: 0,
                          left: 0,
                        }}
                        src={`/assets/images/pasco-wm/pasco${value.images}.jpg`}
                        alt={`${value.title}`}
                      />
                      <img
                        style={{
                          position: "absolute",
                          top: "280px",
                          left: "100px",
                          width: "200px",
                        }}
                        src="/assets/images/logo/tran-logo.png"
                        alt={`${value.title}`}
                      />
                    </div>
                  </div>
                  <div className="content">
                    {/* <p className="portfoliotype">{value.category}</p>
                                        <h4 className="title"><a href="/portfolio-details">{value.title}</a></h4> */}
                    <div className="portfolio-btn">
                      <a
                        className="rn-btn text-white"
                        // href="/portfolio-details"
                        onClick={onAskPriceButtonClick}
                      >
                        Ask for price
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ position: "fixed", zIndex: 100 }}>
          <FloatingWhatsApp
            statusMessage={"Online"}
            avatar="/assets/images/icons/avatar.png"
            phoneNumber="+971586278782"
            accountName={"آدم"}
            chatMessage={"مرحبا.. هل يمكنني مساعدتك؟"}
            allowClickAway={true}
            allowEsc={true}
            styles={{
              position: "fixed",
              width: "50px",
              height: "50px",
              bottom: "40px",
              backgroundColor: "#25d366",
              color: "#FFF",
              borderRadius: "50px",
              textAlign: "center",
              fontSize: "30px",
              boxShadow: "3px 4px 3px #999",
              left: "30px",
              zIndex: 100,
            }}
          />
        </div>
      </div>
      {/* End Portfolio Area  */}

      {/* Start Footer Style  */}
      <FooterTwo />
      {/* End Footer Style  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop
          showUnder={160}
          //   style={{
          //     position: "fixed",
          //     bottom: "100px",
          //     right: "30px",
          //     cursor: "pointer",
          //     transition: "opacity 0.2s linear 0s, visibility",
          //     opacity: 1,
          //     visibility: "visible",
          //   }}
        >
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default CreativePortfolio;
